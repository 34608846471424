import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthHelper } from '../shared/helpers/auth-helper';
import { Configuration } from '../../app.constants';
import { SecureAuthService } from '../../user/shared/services/user.service';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    
    const config: Configuration = inject(Configuration);
    const authService: OAuthService = inject(OAuthService);
    const authHelper: AuthHelper = inject(AuthHelper);
    const router: Router = inject(Router);
    const secureAuthService: SecureAuthService = inject(SecureAuthService);
    
    if (authService.hasValidAccessToken() && authHelper.tokenNotExpired()) {
        return true;
    }
    else if (authService.hasValidAccessToken() && !authHelper.tokenNotExpired()) {
        authService.revokeTokenAndLogout({
            client_id: authService.clientId,
            returnTo: authService.redirectUri
          }, true);
        sessionStorage.removeItem(config.SessionStateKey);
    }

    router.navigate([config.AuthorizeUserComponent]);
    return false;
}
